import axios from 'axios';
import { getCookie } from '../helpers/cookiesStorage';

export const getWalletHistory = (type) => {
	return axios
		.get(`/api/account/wallet-history/?type=${type.toUpperCase()}`, {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const getSingleWalletHistory = (id) => {
	return axios
		.get(`/api/account/wallet-history/${id}`, {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const getStores = () => {
	return axios
		.get('/api/ecommerce/discount/stores', {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const getVouchers = () => {
	return axios
		.get('/api/ecommerce/discount/stores/all-vouchers', {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const getVoucher = (id) => {
	return axios
		.post(`/api/ecommerce/discount/stores/${id}/get-voucher`, undefined, {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const rechargeBalance = (payload) => {
	return axios
		.post(`/api/ecommerce/topup-balance/`, payload, {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const getPurchasedTickets = () => {
	return axios
		.get('/api/account/purchased-tickets?payment_method=paymob', {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const getWheels = () => {
	return axios
		.get('/api/wheel/', {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const getBigWins = () => {
	return axios
		.get('/api/account/big_wins', {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};

export const getCountries = () => {
	return axios
		.get('/api/content/countries', {
			headers: {
				Authorization: `Token ${getCookie('token')}`,
			},
		})
		.then((response) => response)
		.catch((err) => err?.response);
};
