import { useRef } from 'react';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import { Swiper } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import './index.css';
import SliderArrow from './sliderArrow';

export function Slider({ children }) {
	const navigationPrevRef = useRef(null);
	const navigationNextRef = useRef(null);

	return (
		<div style={{ paddingInline: '2rem', position: 'relative' }}>
			<Swiper
				modules={[Navigation, Pagination, A11y]}
				className='default-slider'
				autoHeight={true}
				navigation={{
					prevEl: navigationPrevRef?.current,
					nextEl: navigationNextRef?.current,
				}}
				slidesPerGroupAuto={true}
				slidesPerView={'auto'}
				spaceBetween={10}
				onBeforeInit={(swiper) => {
					swiper.params.navigation.prevEl = navigationPrevRef?.current;
					swiper.params.navigation.nextEl = navigationNextRef?.current;
				}}>
				{children}
				<div className='prev-button' ref={navigationPrevRef}>
					<SliderArrow color={'currentColor'} />
				</div>
				<div className='next-button' ref={navigationNextRef}>
					<SliderArrow color={'currentColor'} />
				</div>
			</Swiper>
		</div>
	);
}

export default Slider;
