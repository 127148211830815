import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store';
import './i18n';
import Snackbar from './components/snackbar';
// production
axios.defaults.baseURL = 'https://app.kazbanakazbana.com/';

// development
// axios.defaults.baseURL = 'https://dev.kazbanakazbana.com/';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
			<Snackbar />
		</Provider>
	</React.StrictMode>
);
