import { useTranslation } from 'react-i18next';
import HottestWheelCard from '../hottestWheelCard';
import styles from './index.module.scss';
import { SwiperSlide } from 'swiper/react';
import ActiveWheelsSlider from '../slider/activeWheelsSlider';

export function HottestWheels({ products }) {
	const { t } = useTranslation();

	if (products) {
		return (
			<div className={styles['hottest-wheels']}>
				<h3 className={styles['hottest-wheels-header']}>{t('hottest_wheel.title')}</h3>
				<ActiveWheelsSlider>
					{products.map((product) => (
						<SwiperSlide key={product.slug}>
							<HottestWheelCard product={product} />
						</SwiperSlide>
					))}
				</ActiveWheelsSlider>
			</div>
		);
	}
}

export default HottestWheels;
