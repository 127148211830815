import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router';
import styles from './index.module.scss';
import Profile from '../../components/profile';
import { retriveProfile } from '../../services/profileService';
import { useDispatch, useSelector } from 'react-redux';
import { setProfile } from '../../store/slices/userSlice';
import Loader from '../../components/loader';
import { useTranslation } from 'react-i18next';

export function MyProfile() {
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(true);
	const { profile } = useSelector((state) => state.user);
	const { tab } = useParams();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const getProfile = async () => {
		setLoading(true);
		const response = await retriveProfile();
		if (response.status === 200) {
			dispatch(setProfile(response.data));
		} else {
			//handle error
		}
		setLoading(false);
	};
	const toogleMenu = () => {
		setOpen(!open);
	};

	useEffect(() => {
		getProfile();
	}, []);

	if (loading) {
		return <Loader />;
	}
	return (
		<div className={styles['my-profile']}>
			<div className={[styles['my-profile-header-container'], open && styles['open-header']].join(' ')}>
				{tab ? (
					<h3 className={styles['my-profile-header']}>{t(`my_profile.${tab}`)}</h3>
				) : (
					<h3 className={styles['my-profile-header']}>{t('my_profile.title')}</h3>
				)}
			</div>
			<div className={[styles['my-profile-container'], open && styles['open']].join(' ')}>
				<Profile open={open} setOpen={setOpen} profile={profile} />
				<Outlet context={{ open, toogleMenu }} />
			</div>
		</div>
	);
}

export default MyProfile;
