import { useState, useMemo, useEffect } from 'react';
import styles from './index.module.scss';

export function OTP(props) {
	const { length, onComplete, inComplete, resetOTP } = props;
	const [value, setValue] = useState('');
	const [error, setError] = useState(false);

	//regular expression to tests whether a string is a digit
	const RExp_DIGIT = new RegExp(/^\d+$/);

	const valueItems = useMemo(() => {
		const valueArray = value.split('');
		const items = [];
		for (let i = 0; i < length; i++) {
			const char = valueArray[i];

			if (RExp_DIGIT.test(char)) {
				items.push(char);
			} else {
				items.push('');
			}
		}

		return items;
	}, [value, length]);

	useEffect(() => {
		if (resetOTP) {
			setValue('');
		}
	}, [resetOTP]);

	const handleFocusOut = async (e) => {
		if (!e.currentTarget.contains(e.relatedTarget)) {
			if (value.length < length) {
				setError(true);
			} else {
				setError(false);
			}
		}
	};

	const focusToNextInput = (target) => {
		const nextElementSibling = target.nextElementSibling;

		if (nextElementSibling) {
			nextElementSibling.focus();
		}
	};

	const focusToPrevInput = (target) => {
		const previousElementSibling = target.previousElementSibling;

		if (previousElementSibling) {
			previousElementSibling.focus();
		}
	};

	const inputOnChange = (e, idx) => {
		setError(false);
		const target = e.target;
		let targetValue = target.value.trim();
		const isTargetValueDigit = RExp_DIGIT.test(targetValue);
		if (targetValue === '') inComplete();
		if (!isTargetValueDigit && targetValue !== '') {
			return;
		}

		const nextInputEl = target.nextElementSibling;

		// only delete digit if next input element has no value
		if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
			return;
		}

		targetValue = isTargetValueDigit ? targetValue : ' ';

		//targetValueLength will always be 1 if the user enter the otp digit by digit
		//but it the user copied the otp number (the OTP input will only accept it if its the same lenght as the max lenght for the OTP input)
		const targetValueLength = targetValue.length;

		if (targetValueLength === 1) {
			let newValue = '';
			//in deleted cill case
			if (!isTargetValueDigit) {
				newValue = value.substring(0, idx);
				setValue(newValue);
				return;
			}
			newValue = value.substring(0, idx) + targetValue;
			setValue(newValue);
			if (idx == length - 1) {
				setError(false);
				onComplete(newValue);
				// target.blur();
			} else {
				focusToNextInput(target);
				const nextElementSibling = target.nextElementSibling;

				if (nextElementSibling) {
					nextElementSibling.focus();
				}
			}
		}
		//in case the user copied the otp
		else if (targetValueLength === length) {
			setValue(targetValue);
			setError(false);
			onComplete(targetValue);
			// target.blur();
		}
	};

	const inputOnKeyDown = (e) => {
		const { key } = e;
		const target = e.target;
		const targetValue = target.value;

		//Foucs next element when click right or down
		if (key === 'ArrowRight' || key === 'ArrowDown') {
			e.preventDefault();
			return focusToNextInput(target);
		}
		//Foucs previous element when left right or up
		if (key === 'ArrowLeft' || key === 'ArrowUp') {
			e.preventDefault();
			return focusToPrevInput(target);
		}

		// keep the selection range position if the same digit was typed
		target.setSelectionRange(0, targetValue.length);

		if (e.key !== 'Backspace' || target.value !== '') {
			return;
		}
		inComplete();
		//prevent foucs on the element in its not the next or alrady typed
		const previousElementSibling = target.previousElementSibling;

		if (previousElementSibling) {
			previousElementSibling.focus();
		}
	};

	const inputOnFocus = (e) => {
		const { target } = e;
		// keep focusing back until previous input element has value
		const prevInputEl = target.previousElementSibling;
		if (prevInputEl && prevInputEl.value === '') {
			return prevInputEl.focus();
		}
		target.setSelectionRange(0, target.value.length);
	};

	return (
		<>
			<div onBlur={handleFocusOut} className={styles['container']}>
				{valueItems.map((digit, idx) => (
					<input
						key={idx}
						type='text'
						inputMode='numeric'
						pattern='\d{1}'
						maxLength={length}
						className={[styles['otp-input'], error ? styles['otp-error'] : ''].join(' ')}
						value={digit}
						onChange={(e) => inputOnChange(e, idx)}
						onFocus={inputOnFocus}
						onKeyDown={inputOnKeyDown}
						placeholder='-'
					/>
				))}
			</div>
			{error && <p className={styles['error']}>Invalid OTP</p>}
		</>
	);
}

export default OTP;
