import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useNavigate, useParams } from 'react-router';
import { getProductBySlug, initiatePayment } from '../../services/productDetailsService';
import ImageViewer from '../../components/imageViewer';
import cup from '../../assets/icons/cup.svg';
import wheelImage from '../../assets/icons/kk-wheel.svg';
import Slider from '../../components/slider';
import { SwiperSlide } from 'swiper/react';
import Ticket from '../../components/ticket';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from '../../store/slices/snackbarSlice';
import { addOrder } from '../../store/slices/orderSlice';
import Button from '../../components/formButton';
import Loader from '../../components/loader';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

export function ProductDetails() {
	const [loading, setLoading] = useState(false);
	const [product, setProduct] = useState(null);
	const [selectedTickets, setSelectedTickets] = useState([]);
	const user = useSelector((state) => state.user);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { slug } = useParams();
	const [searchParams] = useSearchParams();
	const { t } = useTranslation();

	const getProduct = async () => {
		setLoading(true);
		const res = await getProductBySlug(slug);
		if (res.status === 200) {
			setProduct(res.data);
		} else {
			// handle error
		}
		setLoading(false);
	};

	const toogleSelectTicket = (num) => {
		if (selectedTickets.includes(num)) {
			setSelectedTickets(selectedTickets.filter((ticket) => ticket !== num));
		} else {
			setSelectedTickets([...selectedTickets, num]);
		}
	};
	const buyTickets = async () => {
		if (user.user) {
			const payload = {
				wheel_slots: {
					slots: selectedTickets,
				},
				no_of_tickets: selectedTickets.length,
				...(searchParams.get('type') &&
					searchParams.get('wheel') && { order_type: searchParams.get('type').toUpperCase(), wheel_id: +searchParams.get('wheel') }),
			};
			const response = await initiatePayment(slug, payload);
			if (response.status === 200) {
				dispatch(addOrder(response.data));
				navigate('/checkout');
			} else if (response.status === 401) {
				dispatch(
					setSnackbar({
						display: true,
						variant: 'error',
						message: t('login_first_to_buy'),
					})
				);
				navigate('/login');
			} else {
				dispatch(
					setSnackbar({
						display: true,
						variant: 'error',
						message: response.response.data.message,
					})
				);
				dispatch(addOrder(null));
			}
		} else {
			dispatch(
				setSnackbar({
					display: true,
					variant: 'error',
					message: t('login_first_to_buy'),
				})
			);
			navigate('/login');
		}
	};
	const getLabel = (num) => {
		switch (num) {
			case 1:
				return t('product_details.one');
			case 2:
				return t('product_details.two');
			case 3:
				return t('product_details.three');
			case 4:
				return t('product_details.four');
			case 5:
				return t('product_details.five');
			case 6:
				return t('product_details.six');
			default:
				return '';
		}
	};

	useEffect(() => {
		getProduct();
	}, []);

	if (product && !loading) {
		return (
			<div className={styles['product-details']}>
				{product && (
					<>
						<div className={styles['product-details-image-viewer']}>
							<ImageViewer images={product.images.filter((image, index) => index < 4)} thumbnail={product.thumbnail} />
						</div>
						<div className={styles['product-details-content']}>
							<div className={styles['product-details-content-wheel']}>
								<p className={styles['product-details-content-wheel-text']}>
									{t('product_details.wheel_number')} <span className={styles['product-details-content-wheel-number']}>{product.wheel_number}</span>
								</p>
								<p
									className={styles['product-details-content-wheel-text-sec']}
									dangerouslySetInnerHTML={{ __html: t('product_details.spin_number', { num: product.streak_to_win }) }}
								/>
							</div>
							<h3 className={styles['product-details-content-title']}>{product.name}</h3>
							{/* <div className={styles['product-details-content-rating']}>
                  <Rating rating={product.rating} />
                  <p className={styles['product-details-content-rating-text']}>{5} Customer Review</p>
                </div> */}
							{product.win_count > 0 && (
								<div className={styles['product-details-content-won']}>
									<img className={styles['product-details-content-won-image']} src={cup} alt='cup' />
									<p className={styles['product-details-content-won-text']}>
										<span className={styles['product-details-content-won-number']}>{product.win_count}</span>
										{t('product_details.won_before')}
									</p>
								</div>
							)}
							<p className={styles['product-details-content-description']}>{product.description}</p>
							<div className={styles['product-details-content-tickets']}>
								<div>
									{Array.from({ length: product.streak_to_win }).map((_, i) => (
										<img key={_} src={wheelImage} alt='wheel' />
									))}
								</div>
								{/* <p className={styles['product-details-content-tickets-text']}>
									<span className={styles['product-details-content-tickets-number']}>{product.no_of_tickets}</span>
									{t('tickets')}
								</p> */}
								<p className={styles['product-details-content-tickets-text']}>
									<span className={styles['product-details-content-tickets-number']}>{product.price_per_product}</span>
									{t('product_details.egp_ticket')}
								</p>
							</div>
							<div className={styles['slider']}>
								<p className={styles['slider-title']}>
									{t('product_details.select', { number: getLabel(product.streak_to_win - 1), name: product.name })}
								</p>
								<Slider slidesPerView={3} spaceBetween={5}>
									{Array.from({ length: product.no_of_tickets }, (_, i) => i + 1).map((num) => {
										return (
											<SwiperSlide key={num}>
												<Ticket selectedTickets={selectedTickets} number={num} toogleSelectTicket={toogleSelectTicket} />
											</SwiperSlide>
										);
									})}
								</Slider>
								<p className={styles['slider-description']}>{t('product_details.increase')}</p>
							</div>
							<Button disabled={selectedTickets.length === 0} text={t('product_details.buy_button')} handleSubmit={buyTickets} />
						</div>
					</>
				)}
			</div>
		);
	} else {
		return <Loader />;
	}
}

export default ProductDetails;
