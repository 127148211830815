import Navbar from '../../components/navbar';
import CategoryHeader from '../../components/categoryHeader';
import Footer from '../../components/footer';
import styles from './index.module.scss';

export function ProtectedRouteLayout({ page, children }) {
	const applyPadding = () => {
		switch (page) {
			case 'checkout':
				return false;
			case 'my-profile':
				return false;
			case 'live-wheel':
				return false;
			default:
				return true;
		}
	};

	if (page === 'live-wheel') {
		return <main className={applyPadding() ? styles['layout-main'] : styles['layout-main-no-padding']}>{children}</main>;
	} else {
		return (
			<>
				<Navbar />
				<CategoryHeader />
				<main className={applyPadding() ? styles['layout-main'] : styles['layout-main-no-padding']}>{children}</main>
				<Footer />
			</>
		);
	}
}

export default ProtectedRouteLayout;
