import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import { getVoucher } from '../../services/tabServices';
import Button from '../../components/formButton';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from '../../store/slices/snackbarSlice';
import CopyToClipboard from 'react-copy-to-clipboard';

const Redemption = () => {
	const { t } = useTranslation();
	const { id } = useParams();
	const [voucher, setVoucher] = useState();
	const [code, setCode] = useState();
	const [showModal, setShowModal] = useState(false);
	const [textToCopy, setTextToCopy] = useState('');
	const { state } = useLocation();
	const { profile } = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const handleGetVoucher = async () => {
		const response = await getVoucher(id);
		if (response.status === 200) {
			setCode(response.data);
			setShowModal(true);
			setTextToCopy(response.data.vouchers);
		} else {
			dispatch(
				setSnackbar({
					display: true,
					variant: 'error',
					message: response.response.data.message,
				})
			);
		}
	};
	const onCopyText = () => {
		setShowModal(false);
		dispatch(
			setSnackbar({
				display: true,
				variant: 'success',
				message: t('code_copied'),
			})
		);
	};
	const getRedeemCode = () => {
		if (voucher.in_store && voucher.online) {
			return t('redemption.in_store_and_online');
		} else if (voucher.in_store) {
			return t('redemption.in_store');
		} else if (voucher.online) {
			return t('redemption.online');
		}
	};
	useEffect(() => {
		if (state.voucher) {
			setVoucher(state.voucher);
		}
	}, []);

	return (
		<>
			<div className={styles['category-container']}>
				<h2 className={styles['category-header']}>{t('redemption.redemption')}</h2>
				{voucher && (
					<div className={styles['category-content']}>
						<div className={styles['category-content-first']}>
							<img className={styles['category-content-first-image']} src={voucher.image} alt='' />
							<div className={styles['category-content-first-content']}>
								<h3>{voucher.title}</h3>
								<p>{voucher.description}</p>
							</div>
							<img className={styles['category-content-first-logo']} src={voucher.vendor.logo} alt='' />
						</div>
						<div className={styles['category-content-second']}>
							<p className={styles['category-content-second-balance']}>
								<span>{t('redemption.discount_balance')}</span> {profile.discount_won} {t('currency')}
							</p>
							<p className={styles['category-content-second-balance-redeemed']}>
								{t('redemption.discount_balance_after_redemption')} {profile.discount_won - voucher.amount} {t('currency')}
							</p>
							<p>{t('redemption.code_expires', { date: new Date(voucher.expiration_date).toDateString() })}</p>
							<p className={styles['category-content-second-code']}>
								{t('redemption.redeem_code')} <span>{getRedeemCode()}</span>
							</p>
							<p className={styles['category-content-second-amount']}>
								{t('redemption.redeem')} {`${Number(voucher.amount)} ${t('currency')}`}
							</p>
						</div>
						<Button text={t('redeem_get_code')} handleSubmit={handleGetVoucher} />
					</div>
				)}
			</div>
			{showModal && (
				<div className={styles['modal']}>
					<div className={styles['modal-container']}>
						<div className={styles['modal-content']}>
							<p className={styles['modal-header']}>{t('redemption.code')}</p>
							<CopyToClipboard text={textToCopy} onCopy={onCopyText}>
								<p style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
									<p className={styles['modal-text']}>{code.vouchers}</p>
									<Button variant={'outline'}>{t('redemption.copy_code')}</Button>
								</p>
							</CopyToClipboard>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Redemption;
