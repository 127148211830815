import styles from './index.module.scss';
import welcome from '../../assets/icons/welcome.svg';
import reminder from '../../assets/icons/reminder.svg';
import fail from '../../assets/icons/fail.svg';
import bigWin from '../../assets/icons/big-win.svg';
import tracking from '../../assets/icons/tracking.svg';
import deleteIcon from '../../assets/icons/delete-notification.svg';
import { deleteNotification, getNotifications, readNotification } from '../../services/sharedService';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

export default function NotificationCard({ setNotifications, notification, handleCloseNotifications, setOpenReferralModal, setMessage }) {
	const date = new Date(notification?.created_at);
	const navigate = useNavigate();
	const { t } = useTranslation();
	const getLogo = () => {
		switch (notification.type) {
			case 'LOGIN':
				return welcome;
			case 'WHEELREMINDER':
				return reminder;
			case 'FAILEDWHEEL':
				return fail;
			case 'WINWHEEL':
				return bigWin;
			case 'REGISTER':
				return welcome;
			case 'REFFERAL':
				return welcome;
			case 'SHIPPING':
				return tracking;
			default:
				return '';
		}
	};
	const getMonths = (month) => {
		switch (month) {
			case 0:
				return t('january');
			case 1:
				return t('february');
			case 2:
				return t('march');
			case 3:
				return t('april');
			case 4:
				return t('may');
			case 5:
				return t('june');
			case 6:
				return t('july');
			case 7:
				return t('august');
			case 8:
				return t('september');
			case 9:
				return t('october');
			case 10:
				return t('november');
			case 11:
				return t('december');
			default:
				return '';
		}
	};
	const handleCardClick = async (e) => {
		e.stopPropagation();
		await handleReadNotification();
		if (notification.type === 'WHEELREMINDER') {
		} else if (notification.type === 'FAILEDWHEEL') {
			navigate('/my-profile/discount-wins-balance');
		} else if (notification.type === 'WINWHEEL') {
			navigate('/my-profile/big-wins');
		} else if (notification.type === 'REFFERAL') {
			setMessage(notification.message);
			setOpenReferralModal(true);
		} else {
			return;
		}
		handleCloseNotifications();
	};
	const handleReadNotification = async () => {
		const response = await readNotification(notification.id);
		if (response.status === 200) {
			const notificationRes = await getNotifications();
			if (notificationRes.status === 200) {
				setNotifications(notificationRes.data);
			}
		}
	};
	const handleDeleteNotification = async (e) => {
		e.stopPropagation();
		const response = await deleteNotification(notification.id);
		if (response.status === 204) {
			const notificationRes = await getNotifications();
			if (notificationRes.status === 200) {
				setNotifications(notificationRes.data);
			}
		}
	};

	return (
		<div onClick={handleCardClick} className={`${styles['card']} ${notification.read_at ? styles['card-read'] : styles['card-unread']}`}>
			<div className={styles['card-delete']}>
				<img onClick={handleDeleteNotification} className={styles['card-delete-icon']} src={deleteIcon} alt='' />
			</div>
			<img src={getLogo()} alt='' />
			<div className={styles['card-content']}>
				<p className={styles['card-title']}>{notification.message}</p>
				<p className={styles['card-date']}>{`${getMonths(date.getMonth())} ${date.getDate()}, ${date.getFullYear()}, ${
					date.getHours() > 12
						? (date.getHours() - 12).toLocaleString('en-US', { minimumIntegerDigits: 2 })
						: date.getHours().toLocaleString('en-US', { minimumIntegerDigits: 2 })
				}:${date.getMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2 })} ${date.getHours() >= 12 ? 'PM' : 'AM'}`}</p>
			</div>
		</div>
	);
}
