import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useDispatch } from 'react-redux';
import { getVouchers } from '../../services/tabServices';
import talabatTicket from '../../assets/images/talabat-ticket.png';
import talabatTicketAr from '../../assets/images/talabat-ticket-ar.png';
import FilledStar from '../filledStar';
import list from '../../assets/icons/list.svg';
import sandClock from '../../assets/icons/sand-clock.svg';
import { useNavigate } from 'react-router';
import SwipeToUse from '../swipeToUse';
import SuccessVoucherModal from '../successVoucherModal';
import FailVoucherModal from '../failVoucherModal';
import { setSnackbar } from '../../store/slices/snackbarSlice';
import Loader from '../loader';
import { useTranslation } from 'react-i18next';
import VoucherCard from '../voucherCard';

export default function VoucherTab() {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();
	const [voucher, setVoucher] = useState();
	const [available, setAvailable] = useState();
	const [needed, setNeeded] = useState();
	const [successModal, setSuccessModal] = useState(false);
	const [failModal, setFailModal] = useState(false);
	const [counter, setCounter] = useState(0);
	const [timerId, setTimerId] = useState(null);
	const [disable, setDisable] = useState(false);
	const { i18n, t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleGetVouchers = async () => {
		setLoading(true);
		const response = await getVouchers();
		if (response.status === 200) {
			if (response.data) {
				setData(response.data);
			}
		} else {
			dispatch(
				setSnackbar({
					display: true,
					variant: 'error',
					message: response.response.data.message,
				})
			);
		}
		setLoading(false);
	};

	useEffect(() => {
		handleGetVouchers();
	}, []);

	if (data && !loading) {
		return (
			<div className={styles['discount']}>
				<div className={styles['discount-container']}>
					{data?.length > 0 &&
						data?.map((voucher) => (
							<div className={styles['discount-product']} key={voucher.id}>
								<VoucherCard voucher={voucher} />
							</div>
						))}
				</div>
			</div>
		);
	} else {
		return <Loader />;
	}
}
