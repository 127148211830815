import styles from './index.module.scss';
import trendingUp from '../../assets/icons/trending-up.svg';
import trendingDown from '../../assets/icons/trending-down.svg';
import { useState } from 'react';
import Button from '../formButton';
import { useTranslation } from 'react-i18next';

export default function OtherTransactionsCard({ transaction }) {
	const [showModal, setShowModal] = useState(false);
	const { t } = useTranslation();
	const date = new Date(transaction.updated_at);

	const handleShowModal = () => {
		setShowModal(true);
	};
	const handleCloseModal = () => {
		setShowModal(false);
	};

	const getMonths = (month) => {
		switch (month) {
			case 0:
				return t('january');
			case 1:
				return t('february');
			case 2:
				return t('march');
			case 3:
				return t('april');
			case 4:
				return t('may');
			case 5:
				return t('june');
			case 6:
				return t('july');
			case 7:
				return t('august');
			case 8:
				return t('september');
			case 9:
				return t('october');
			case 10:
				return t('november');
			case 11:
				return t('december');
			default:
				return '';
		}
	};
	return (
		<>
			<div className={styles['card']} onClick={handleShowModal}>
				<img className={styles['card-icon']} src={trendingDown} alt='' />
				<div className={styles['card-product']}>
					<h3 className={styles['card-product-title']}>{transaction.product_data.name}</h3>
					<p className={styles['card-product-date']}>{`${getMonths(date.getMonth())} ${date.getDate()}, ${date.getFullYear()}, ${
						date.getHours() > 12
							? (date.getHours() - 12).toLocaleString('en-US', { minimumIntegerDigits: 2 })
							: date.getHours().toLocaleString('en-US', { minimumIntegerDigits: 2 })
					}:${date.getMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2 })} ${date.getHours() >= 12 ? t('pm') : t('am')}`}</p>
				</div>
				<p className={`${styles['card-amount']} ${styles['down']}`}>
					{Number(transaction.total_amount).toFixed(0)} {t('currency')}
				</p>
			</div>
			{showModal && (
				<div className={styles['modal']}>
					<div className={styles['modal-container']}>
						<img className={styles['modal-icon']} src={trendingDown} alt='' />
						<div className={styles['modal-content']}>
							<p className={styles['modal-header']}>{t('my_profile.direct_purchase')}</p>
							<ul className={styles['modal-list']}>
								<li className={styles['modal-list-item']}>
									{t('my_profile.wheel_number')} <span>{transaction.product_data.wheel_number}</span>
								</li>
								<li className={styles['modal-list-item']}>
									{t('my_profile.transaction_date')}{' '}
									<span>{`${new Date(date).getDate()}/${new Date(date).getMonth() + 1}/${new Date(date).getFullYear()}, ${
										new Date(date).getHours() > 12
											? (new Date(date).getHours() - 12).toLocaleString('en-US', { minimumIntegerDigits: 2 })
											: new Date(date).getHours().toLocaleString('en-US', { minimumIntegerDigits: 2 })
									}:${new Date(date).getMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2 })} ${
										new Date(date).getHours() >= 12 ? t('pm') : t('am')
									}`}</span>
								</li>
								<li className={styles['modal-list-item']}>
									{t('my_profile.transaction_status')} <span>{t('my_profile.done_successfully')}</span>
								</li>
								<li className={styles['modal-list-item']}>
									{t('my_profile.payment_method')} <span>{transaction.payment_method.replaceAll('-', ' ')}</span>
								</li>
								<li className={styles['modal-list-item']}>
									{t('my_profile.ticket_numbers')} <span>{transaction.wheel_slots.slots.join(', ')}</span>
								</li>
								<li className={styles['modal-list-item']}>
									{t('my_profile.ticket_price')}{' '}
									<span>
										{transaction.product_data.price_per_product} {t('currency')}
									</span>
								</li>
								<li className={styles['modal-list-item']}>
									{t('my_profile.total_amount')}{' '}
									<span>
										{transaction.product_data.price_per_product * transaction.wheel_slots.slots.length} {t('currency')}
									</span>
								</li>
							</ul>
							<Button handleSubmit={handleCloseModal} text={t('my_profile.done_button')} type={'button'} />
						</div>
					</div>
				</div>
			)}
		</>
	);
}
