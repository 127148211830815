import styles from './index.module.scss';
import Button from '../formButton';
import unQualified from '../../assets/images/unqualified.gif';
import { useTranslation } from 'react-i18next';

export function UnQualifiedModal({ streak_to_win, onModalSubmit, onModalDecline, isFinished, price }) {
	const { t } = useTranslation();
	const getTitle = () => {
		if (streak_to_win === 1) {
			return t('live_wheel.unqualified_one');
		} else if (streak_to_win >= 2 && !isFinished) {
			return t('live_wheel.unqualified_two');
		} else if (isFinished) {
			return t('live_wheel.unqualified_three');
		}
	};
	return (
		<div className={styles['modal']}>
			<div className={styles['modal-container']}>
				<img src={unQualified} alt='unqualified' className={styles['modal-image']} />
				<div className={styles['modal-content']}>
					<h2 className={styles['modal-title']}>{getTitle()}</h2>
					<p className={styles['modal-text']}>
						{t('live_wheel.you_are_out')} {price}
						{t('currency')} {t('live_wheel.as_discount')}
					</p>
					<div className={styles['modal-content-actions']}>
						<Button handleSubmit={onModalSubmit} text={t('live_wheel.continue_watching')} type={'button'} />
						<Button handleSubmit={onModalDecline} text={t('live_wheel.check_discount')} variant={!isFinished && 'outline'} type={'button'} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default UnQualifiedModal;
