import { Autoplay, EffectCoverflow } from 'swiper/modules';
import { Swiper } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
// import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';
// import 'swiper/css/free-mode';
// import 'swiper/css/pagination';
import './index.css';
import { useEffect, useState } from 'react';

export function AutoSlider({ children, number_of_tickets, normal }) {
	const [swiper, setSwiper] = useState(null);
	useEffect(() => {
		if (swiper && number_of_tickets > 3) {
			const timer = setInterval(() => {
				swiper.slideNext();
			}, 3000);
			return () => {
				clearInterval(timer);
			};
		}
	}, [swiper]);

	if (normal) {
		return (
			<div style={{ alignSelf: 'center', width: '100%' }}>
				<Swiper
					// install Swiper modules
					modules={[Autoplay]}
					loop={true}
					onSwiper={setSwiper}
					spaceBetween={10}
					speed={1000}
					slidesPerView={'auto'}
					centeredSlides={true}
					autoplay={{
						delay: 2000,
					}}
					className='auto-swiper'>
					{children}
				</Swiper>
			</div>
		);
	} else if (number_of_tickets >= 3) {
		return (
			<div style={{ alignSelf: 'center', maxWidth: '550px', width: '550px' }}>
				<Swiper
					// install Swiper modules
					modules={[EffectCoverflow, Autoplay]}
					loop={true}
					autoplay={{
						delay: 2000,
					}}
					speed={1000}
					slidesPerView={'auto'}
					effect={'coverflow'}
					centeredSlides={true}
					coverflowEffect={{
						rotate: 180,
						stretch: -100,
						depth: 200,
						modifier: 1,
						slideShadows: false,
					}}
					className='auto-swiper'>
					{children}
				</Swiper>
			</div>
		);
	} else {
		return (
			<div style={{ alignSelf: 'center', maxWidth: '550px', width: '550px' }}>
				<Swiper
					// install Swiper modules
					slidesPerView={number_of_tickets}
					slidesPerGroup={number_of_tickets}
					className='non-auto-swiper'>
					{children}
				</Swiper>
			</div>
		);
	}
}

export default AutoSlider;
