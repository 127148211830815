import { useEffect, useState } from 'react';
import FormSplash from '../../components/formSplash';
import SignupForm from '../../components/signupForm';
import backArrow from '../../assets/icons/back-arrow.svg';
import styles from './index.module.scss';
import OTPStep from '../../components/otpStep';
import { register } from '../../services/signupService';
import { sendOTP } from '../../services/authSharedService';
import { setUser } from '../../store/slices/userSlice';
import { setSnackbar } from '../../store/slices/snackbarSlice';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function Signup() {
	const [step, setStep] = useState(1);
	const [data, setData] = useState(null);
	const [OTP, setOTP] = useState('');
	const [searchParams, setSearchParams] = useSearchParams();
	const [referalCode, setReferalCode] = useState('');
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const handleFormSubmit = async (data) => {
		const payload = {
			phone_number: '0' + data.mobile_number,
		};
		const response = await sendOTP(payload);
		if (response.status === 200) {
			dispatch(
				setSnackbar({
					display: true,
					variant: 'success',
					message: response.data.message,
				})
			);
			setData(data);
			setStep(2);
		} else {
			dispatch(
				setSnackbar({
					display: true,
					variant: 'error',
					message: response.response.data.message,
				})
			);
		}
	};
	const handleOTPSubmit = async () => {
		const { mobile_number, full_name, email, password1 } = data;
		const payload = {
			full_name,
			...(email && { email }),
			password1,
			'accept-terms': data['accept-terms'],
			mobile_number: '0' + mobile_number,
			...(referalCode && { referral_code: referalCode }),
			is_web: true,
			otp: OTP,
		};
		const response = await register(payload);
		if (response.status === 201) {
			dispatch(
				setSnackbar({
					display: true,
					variant: 'success',
					message: t('signup_success'),
				})
			);
			dispatch(
				setUser({
					token: response.data.key,
					user: response.data.user,
				})
			);
		} else {
			dispatch(
				setSnackbar({
					display: true,
					variant: 'error',
					message: response.response.data.otp[0],
				})
			);
		}
	};
	const handleResendOTP = async () => {
		const payload = {
			phone_number: '0' + data.mobile_number,
		};
		const response = await sendOTP(payload);
		return response;
	};
	const handleBack = () => {
		if (step > 1) {
			setStep((prev) => prev - 1);
		}
	};

	useEffect(() => {
		const referalCode = searchParams.get('referral_code');
		if (referalCode) {
			setReferalCode(referalCode);
		}
	}, []);

	return (
		<main className={styles['signup-container']}>
			<FormSplash />
			<section className={styles['signup-aside']}>
				{step > 1 && (
					<button className={styles['signup-back']} onClick={handleBack}>
						<img src={backArrow} alt='back' />
					</button>
				)}
				{step === 1 && <SignupForm data={data} handleFormSubmit={handleFormSubmit} />}
				{step === 2 && (
					<OTPStep
						minutes={1}
						handleBack={handleBack}
						handleOTPSubmit={handleOTPSubmit}
						handleResendOTP={handleResendOTP}
						setOTP={setOTP}
						phone={data?.phone}
					/>
				)}
			</section>
		</main>
	);
}

export default Signup;
