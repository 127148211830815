import BigWinsTab from '../BigWinsTab';
import BalanceTab from '../balanceTab';
import DeliverToYouTab from '../deliverToYouTab';
import DiscountTab from '../discountTab';
import FavouritesTab from '../favouritesTab';
import HelpAndSupportTab from '../helpAndSupportTab';
import MyWheelsTab from '../myWheelsTab';
import OtherTransactionsTab from '../otherTransactionsTab';
import ReferFriendTab from '../referFriendTab';
import SettingsTab from '../settingsTab';
import TopUpTab from '../topUpTab';
import VoucherTab from '../voucherTab';
import styles from './index.module.scss';
import menuIcon from '../../assets/icons/menu.svg';
import { useOutletContext, useParams } from 'react-router';

export function Tab() {
	const { open, toogleMenu } = useOutletContext();
	const { tab } = useParams();
	const getTab = () => {
		switch (tab) {
			case 'favourites':
				return <FavouritesTab />;
			case 'discount-wins-balance':
				return <DiscountTab />;
			case 'available-balance':
				return <BalanceTab />;
			case 'big-wins':
				return <BigWinsTab />;
			case 'top-up':
				return <TopUpTab />;
			case 'other-transactions':
				return <OtherTransactionsTab />;
			case 'my-wheels':
				return <MyWheelsTab />;
			case 'settings':
				return <SettingsTab />;
			case 'help-and-support':
				return <HelpAndSupportTab />;
			case 'deliver-to-you':
				return <DeliverToYouTab />;
			case 'refer-friend':
				return <ReferFriendTab />;
			case 'discount-store':
				return <VoucherTab />;
			default:
				return;
		}
	};

	return (
		<div
			className={[
				styles['tab'],
				tab === 'discount-store' ? styles['tab-spaceless'] : tab === 'favourites' || tab === 'my-wheels' ? styles['no-padding'] : '',
				open && styles['open'],
			].join(' ')}>
			<img className={styles['tab-menu']} onClick={toogleMenu} src={menuIcon} alt='' />
			{getTab()}
		</div>
	);
}

export default Tab;
