import Dropdown from '../dropdown';
import styles from './index.module.scss';
import globe from '../../assets/icons/globe.svg';
import world from '../../assets/icons/globe-lang.svg';
import { useEffect, useState } from 'react';
import { setSnackbar } from '../../store/slices/snackbarSlice';
import { useDispatch } from 'react-redux';
import { getCountries } from '../../services/sharedService';
import Loader from '../loader';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import deleteIcon from '../../assets/icons/delete-icon.svg';
import close from '../../assets/icons/close.svg';
import logout from '../../assets/icons/logout-modal.svg';
import Button from '../formButton';
import { setUser } from '../../store/slices/userSlice';
import { useNavigate } from 'react-router';
import { deleteAccount } from '../../services/loginService';

const languages = [
	{
		id: 0,
		slug: 'en-US',
		name: 'English',
	},
	{
		id: 1,
		slug: 'ar-EG',
		name: 'العربيه',
	},
];

export default function SettingsTab() {
	const [countries, setCountries] = useState(null);
	const [selectedCountry, setSelectedCountry] = useState();
	const [selectedLanguage, setSelectedLanguage] = useState();
	const [openModal, setOpenModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleGetCountries = async () => {
		setLoading(true);
		const response = await getCountries();
		if (response.status === 200) {
			if (response.data) {
				setCountries(response.data);
				setSelectedCountry(response.data?.length > 0 ? response.data?.find((country) => country.name === 'Egypt').name : null);
			}
		} else {
			dispatch(
				setSnackbar({
					display: true,
					variant: 'error',
					message: response.response.data.message,
				})
			);
		}
		setLoading(false);
	};
	const handleDelete = async () => {
		setLoading(true);
		const response = await deleteAccount();
		if (response.status === 200) {
			dispatch(setUser({ token: '', user: null, profile: null }));
			navigate('/');
		} else {
			dispatch(
				setSnackbar({
					display: true,
					variant: 'error',
					message: response.response.data.detail ?? response.response.data.message,
				})
			);
		}
	};
	const handleOpenModal = () => {
		setOpenModal(true);
	};
	const handleModalClose = () => {
		setOpenModal(false);
	};

	useEffect(() => {
		handleGetCountries();
	}, []);

	useEffect(() => {
		if (i18n.language === 'en-US') {
			setSelectedLanguage('English');
		} else {
			setSelectedLanguage('العربيه');
		}
	}, [i18n.language]);

	useEffect(() => {
		if (selectedLanguage) {
			if (selectedLanguage === 'English') {
				i18n.changeLanguage('en-US');
				document.dir = 'ltr';
				document.documentElement.lang = 'en';
				axios.defaults.headers.common['Accept-Language'] = 'en';
			} else {
				i18n.changeLanguage('ar-EG');
				document.dir = 'rtl';
				document.documentElement.lang = 'ar';
				axios.defaults.headers.common['Accept-Language'] = 'ar';
			}
		}
	}, [selectedLanguage]);

	if (loading) {
		return <Loader />;
	}
	return (
		<>
			<div className={styles['settings']}>
				<h3 className={styles['settings-text']}>{t('my_profile.settings')}</h3>
				<div className={styles['settings-dropdown']}>
					<Dropdown
						name={'countries'}
						selected={selectedCountry}
						setSelected={setSelectedCountry}
						icon={globe}
						label={t('country')}
						options={countries}
					/>
					<Dropdown
						name={'languages'}
						selected={selectedLanguage}
						setSelected={setSelectedLanguage}
						icon={world}
						label={t('language')}
						options={languages}
					/>
					<button className={styles['settings-delete']} onClick={handleOpenModal}>
						<img src={deleteIcon} alt='' />
						{t('my_profile.delete_account')}
					</button>
				</div>
			</div>
			{openModal && (
				<div className={styles['modal']}>
					<div className={styles['modal-container']}>
						<img onClick={handleModalClose} src={close} alt='close' className={styles['modal-close']} />
						<img src={logout} alt='log out' />
						<p className={styles['modal-text']}>{t('navbar.delete_account_description')}</p>
						<Button variant={'warning'} text={t('my_profile.delete_account')} handleSubmit={handleDelete} />
						<Button type={'button'} text={t('navbar.cancel_button')} handleSubmit={handleModalClose} />
					</div>
				</div>
			)}
		</>
	);
}
