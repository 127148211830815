import styles from './index.module.scss';
import cup from '../../assets/icons/cup.svg';
import logo from '../../assets/icons/logo.svg';
import wheel from '../../assets/icons/kk-wheel.svg';
import emptyHeart from '../../assets/icons/empty-heart.svg';
import filledHeart from '../../assets/icons/filled-heart.svg';
import { useNavigate } from 'react-router';
import VerticalSlider from '../slider/verticalSlider';
import { SwiperSlide } from 'swiper/react';
import { useDispatch, useSelector } from 'react-redux';
import { addToFavourites, getFavouriteProducts, removeFromFavourites } from '../../services/sharedService';
import { setSnackbar } from '../../store/slices/snackbarSlice';
import { setFavourites } from '../../store/slices/favouritesSlice';
import { useTranslation } from 'react-i18next';

export function Card({ product }) {
	const favourites = useSelector((state) => state.favourites);
	const user = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const handleProductNavigate = () => {
		navigate(`/product/${product.slug}`);
	};
	const handleGetFavourites = async () => {
		const response = await getFavouriteProducts();
		if (response.status === 200) {
			dispatch(setFavourites(response.data));
		} else if (response.status === 401) {
			navigate('/login');
		}
	};
	const handleFavoriteClick = async (e) => {
		e.stopPropagation();
		if (user) {
			if (favourites.length > 0) {
				if (favourites.some((favourite) => favourite.slug === product.slug)) {
					const response = await removeFromFavourites(product.slug);
					if (response.status === 200) {
						handleGetFavourites();
					} else if (response.status === 401) {
						navigate('/login');
					} else {
						dispatch(
							setSnackbar({
								display: true,
								variant: 'error',
								message: response.response.data.message,
							})
						);
					}
				} else {
					const response = await addToFavourites(product.slug);
					if (response.status === 200) {
						handleGetFavourites();
					} else if (response.status === 401) {
						navigate('/login');
					} else {
						dispatch(
							setSnackbar({
								display: true,
								variant: 'error',
								message: response.response.data.detail,
							})
						);
					}
				}
			} else {
				const response = await addToFavourites(product.slug);
				if (response.status === 200) {
					handleGetFavourites();
				} else if (response.status === 401) {
					navigate('/login');
				} else {
					dispatch(
						setSnackbar({
							display: true,
							variant: 'error',
							message: response.response.data.detail,
						})
					);
				}
			}
		} else {
			dispatch(
				setSnackbar({
					display: true,
					variant: 'error',
					message: t('login_first'),
				})
			);
			navigate('/login');
		}
	};

	return (
		<div className={styles['product-border']} onClick={handleProductNavigate}>
			<div className={styles['product']}>
				<img
					onClick={handleFavoriteClick}
					src={product.is_favourite || favourites.some((favourite) => favourite.slug === product.slug) ? filledHeart : emptyHeart}
					className={styles['product-favourite']}
					alt='favourite'
				/>
				<div className={styles['product-image-container']}>
					<img src={product.thumbnail} className={styles['product-image']} alt={product.name} />
				</div>
				<div className={styles['product-wrapper']}>
					<div className={styles['product-container']}>
						<h3 className={styles['product-name']}>{product.name}</h3>
						<div className={styles['product-extra']}>
							<p className={styles['product-extra-price']}>
								<span className={styles['product-extra-price-number']}>{product.price_per_product}</span>
								{t('currency')}
							</p>
						</div>
					</div>
					<p
						className={styles['product-extra-slots']}
						dangerouslySetInnerHTML={{ __html: t('product_details.spin_number', { num: product?.streak_to_win }) }}
					/>
				</div>
				<div className={styles['product-footer']}>
					<VerticalSlider>
						<SwiperSlide>
							<img src={cup} className={styles['product-footer-image']} alt='' />
							<p className={styles['product-footer-text']}>
								{product.win_count} {t('hottest_wheel.won_before')}
							</p>
						</SwiperSlide>
						<SwiperSlide>
							<img src={wheel} className={styles['product-footer-image']} alt='' />
							<p className={styles['product-footer-text']}>
								{product.today_wheels_count} {t('hottest_wheel.played_today')}
							</p>
						</SwiperSlide>
						<SwiperSlide>
							<img src={logo} className={styles['product-footer-image']} alt='' />
							<p className={styles['product-footer-text']}>
								{product.today_tickets_count === null ? 0 : product.today_tickets_count} {t('hottest_wheel.bought_today')}
							</p>
						</SwiperSlide>
					</VerticalSlider>
				</div>
			</div>
		</div>
	);
}

export default Card;
