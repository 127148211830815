import { useState, useEffect, useRef } from 'react';
import styles from './index.module.scss';
import LiveWheelAnimation from '../../components/liveWheelAnimation';
import Wheel from '../../components/wheel';
import { useNavigate, useParams } from 'react-router';
import { getSingleWheel, spinWheel } from '../../services/liveWheelService';
import whiteLogo from '../../assets/images/navbarEnLogoWhite.png';
import whiteLogoAr from '../../assets/images/navbarArLogoWhite.png';
import AutoSlider from '../../components/slider/autoSlider';
import Ticket from '../../components/ticket';
import { SwiperSlide } from 'swiper/react';
import QualifiedModal from '../../components/qualifiedModal';
import UnQualifiedModal from '../../components/unQualifiedModal';
import WinnerModal from '../../components/winnerModal';
import countDown from '../../assets/audios/count-down.mp3';
import wheelSpin from '../../assets/audios/spin.mp3';
import inProgress from '../../assets/audios/in-progress.mp3';
import qualified from '../../assets/audios/qualified.wav';
import lose from '../../assets/audios/lose.mp3';
import win from '../../assets/audios/Win.mp3';
import { useTranslation } from 'react-i18next';
import { setSnackbar } from '../../store/slices/snackbarSlice';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

export function LiveWheel() {
	const [showLiveAnimation, setShowLiveAnimation] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [willStart, setWillStart] = useState(true);
	const [ended, setEnded] = useState(false);
	const [startWheel, setStartWheel] = useState(false);
	const [wheelData, setWheelData] = useState(null);
	const [currentSpin, setCurrentSpin] = useState(null);
	const [currentIteration, setCurrentIteration] = useState(1);
	const [slotsNumber, setSlotsNumber] = useState(null);
	const [currentLabels, setCurrentLabels] = useState(null);
	const [nextLabels, setNextLabels] = useState(null);
	const [winnerSlot, setWinnerSlot] = useState(null);
	const [lastIterationRange, setLastIterationRange] = useState(null);
	const renderCount = useRef(1);
	const { t, i18n } = useTranslation();
	const params = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const liveAnimationAudioRef = useRef(null);
	const wheelSpinAudioRef = useRef(null);
	const inProgressAudioRef = useRef(null);
	const dispatch = useDispatch();

	const handleFinishCounter = () => {
		setShowLiveAnimation(false);
		const id = setTimeout(() => {
			setStartWheel(true);
			setWillStart(false);
			clearTimeout(id);
		}, 1000);
	};
	const handleFinishWheelSpin = async () => {
		setStartWheel(false);
		setEnded(true);
		setLastIterationRange(`${currentSpin?.win_slot_range_start}-${currentSpin?.win_slot_range_end}`);
		const id = setTimeout(() => {
			setShowModal(true);
			clearTimeout(id);
		}, 3000);
	};
	const handleWheelSpin = async (body) => {
		const response = await spinWheel(params.id, body);
		if (response.status === 200) {
			setLastIterationRange(`1-8`);
			setCurrentSpin(response.data.data);
			setSlotsNumber(8);
			const labels = [1, 2, 3, 4, 5, 6, 7, 8].reverse(``);
			setCurrentLabels(labels);
			setNextLabels(labels);
			setWinnerSlot(response.data.data.winner_ticket);
		}
	};
	const getWheelData = async () => {
		const response = await getSingleWheel(params.id);
		if (response.status === 200) {
			setWheelData(response.data);
			setCurrentIteration(response.data.iterations.length);
			setSlotsNumber(8);
			if (searchParams.get('ticket')) {
				const body = { ticket: Number(searchParams.get('ticket')) };
				const res = await handleWheelSpin(body);
				getWheelDataOnly();
			} else {
				handleWheelSpin();
			}
		} else {
			// handle error
		}
	};
	const getWheelDataOnly = async () => {
		const response = await getSingleWheel(params.id);
		if (response.status === 200) {
			setWheelData(response.data);
			setCurrentIteration(response.data.iterations.length);
			setSlotsNumber(8);
		} else {
			// handle error
		}
	};
	const onQualifiedModalSubmit = async (selectedTickets) => {
		if (selectedTickets.length === 0) {
			dispatch(
				setSnackbar({
					display: true,
					variant: 'error',
					message: t('select_ticket'),
				})
			);
			return;
		}
		setShowModal(false);
		setCurrentIteration((prev) => prev + 1);
		setCurrentLabels(nextLabels);
		setNextLabels(null);
		setEnded(false);
		setWillStart(true);
		const body = { ticket: selectedTickets[0] };
		const res = await handleWheelSpin(body);
		getWheelDataOnly();
		const id = setTimeout(() => {
			setStartWheel(true);
			setWillStart(false);
			clearTimeout(id);
		}, 3000);
		// navigate(`/product/${wheelData?.product.slug}?type=iteration&wheel=${wheelData?.id}`);
	};
	const onUnQualifiedModalSubmit = () => {
		navigate(`/product/${wheelData?.product.slug}`);
	};
	const onUnQualifiedModalDecline = () => {
		navigate('/my-profile/discount-wins-balance');
	};
	const onWinnerModalSubmit = () => {
		navigate('/my-profile/big-wins');
	};
	const getWheelIteration = (iteration) => {
		if (iteration + 1 === wheelData?.product.streak_to_win) {
			return i18n.language === 'en-US' ? `Final` : 'النهائي';
		}
		switch (iteration) {
			case 0:
				return i18n.language === 'en-US' ? `First` : 'أول';
			case 1:
				return i18n.language === 'en-US' ? `Second` : 'ثاني';
			case 2:
				return i18n.language === 'en-US' ? `Third` : 'ثالث';
			case 3:
				return i18n.language === 'en-US' ? `Fourth` : 'رابع';
			case 4:
				return i18n.language === 'en-US' ? `Fifth` : 'خامس';
			case 5:
				return i18n.language === 'en-US' ? `Sixth` : 'سادس';
			default:
				return i18n.language === 'en-US' ? `${iteration - 1}th` : `${iteration - 1}`;
		}
	};

	useEffect(() => {
		if (renderCount?.current === 1 && params.id) {
			getWheelData();
			renderCount.current++;
		}
	}, []);
	useEffect(() => {
		if (showLiveAnimation && liveAnimationAudioRef.current) {
			liveAnimationAudioRef.current.play();
		}
	}, [showLiveAnimation]);
	useEffect(() => {
		if (startWheel && wheelSpinAudioRef.current) {
			wheelSpinAudioRef.current.play();
		} else if (!startWheel && wheelSpinAudioRef.current) {
			wheelSpinAudioRef.current.pause();
			wheelSpinAudioRef.current.currentTime = 0;
		}
	}, [startWheel]);
	useEffect(() => {
		if (
			(currentSpin && !startWheel && willStart && !ended && currentIteration === 1) ||
			(currentSpin && !startWheel && willStart && !ended && currentIteration > 1)
		) {
			inProgressAudioRef.current?.play();
		}
	}, [currentIteration, currentSpin, ended, startWheel, willStart]);

	return (
		<div className={styles['live-wheel']}>
			{showLiveAnimation && <LiveWheelAnimation handleFinishCounter={handleFinishCounter} />}
			{showLiveAnimation && (
				<audio ref={liveAnimationAudioRef}>
					<source src={countDown} type='audio/mpeg' />
				</audio>
			)}
			<audio ref={wheelSpinAudioRef}>
				<source src={wheelSpin} type='audio/mpeg' />
			</audio>
			<section className={styles['live-wheel-details']}>
				<div className={styles['live-wheel-logo']}>
					<img src={i18n.language === 'en-US' ? whiteLogo : whiteLogoAr} className={styles['live-wheel-details-logo']} alt='kasbana logo' />
				</div>
				<div className={styles['live-wheel-details-content']}>
					<div className={styles['live-wheel-details-content-section']}>
						<h3 className={styles['live-wheel-details-content-title']}>
							{t('live_wheel.live_wheel')}
							<p
								className={styles['live-wheel-details-content-title-sec']}
								dangerouslySetInnerHTML={{ __html: t('product_details.spin_number', { num: wheelData?.product.streak_to_win }) }}
							/>
						</h3>
						<p className={styles['live-wheel-details-content-text']}>
							<span className={styles['live-dot']}></span>
							{t('live_wheel.live_now')}
						</p>
					</div>
					<div className={styles['live-wheel-details-content-section']}>
						<div className={styles['live-wheel-details-product']}>
							<div className={styles['live-wheel-details-product-content']}>
								<p className={styles['live-wheel-details-product-title']}>{t('live_wheel.product_name')}</p>
								<h4 className={styles['live-wheel-details-product-name']}>{wheelData?.product.name}</h4>
								<p className={styles['live-wheel-details-product-wheel']}>
									{t('live_wheel.wheel_number')} <span>{wheelData?.product.wheel_number}</span>
								</p>
							</div>
							<img className={styles['live-wheel-details-product-image']} src={wheelData?.product.thumbnail} alt={wheelData?.product.name} />
						</div>
					</div>
					<div className={styles['live-wheel-details-content-section']}>
						{wheelData?.iterations.map((iteration, index) => (
							<>
								<p className={styles['live-wheel-details-tickets-title']}>
									{iteration?.ticket_number.slots.length > 1
										? t('live_wheel.your_tickets', { iteration: getWheelIteration(index) })
										: t('live_wheel.your_ticket', { iteration: getWheelIteration(index) })}
								</p>
								<div className={styles['tickets-container']}>
									{iteration?.ticket_number.slots.length > 3 ? (
										<AutoSlider normal={true} number_of_tickets={iteration?.ticket_number.slots.length > 3}>
											{iteration?.ticket_number.slots.map((number) => (
												<SwiperSlide key={number}>
													<Ticket selectedTickets={iteration?.ticket_number.slots} number={number} />
												</SwiperSlide>
											))}
										</AutoSlider>
									) : (
										<div className={styles['tickets-container-small']}>
											{iteration?.ticket_number.slots.map((number) => (
												<Ticket key={number} selectedTickets={iteration?.ticket_number.slots} number={number} />
											))}
										</div>
									)}
								</div>
							</>
						))}
					</div>
				</div>
			</section>
			<section className={styles['wheel']}>
				<p className={styles['wheel-title']}>
					{currentSpin && (
						<>
							{getWheelIteration(wheelData?.iteration_number === 0 ? wheelData?.iteration_number : wheelData?.iteration_number - 1)}{' '}
							{t('live_wheel.wheel_iteration')}{' '}
							<span className={styles['wheel-title-slots']}>
								{wheelData?.iteration_number === wheelData.product?.streak_to_win ? t('live_wheel.very_close_to_win') : t('live_wheel.close_to_win')}
							</span>
						</>
					)}
					{currentSpin && !startWheel && willStart && !ended && currentIteration === 1 && (
						<audio ref={inProgressAudioRef}>
							<source src={inProgress} type='audio/mpeg' />
						</audio>
					)}
					{currentSpin && startWheel && !willStart && !ended && currentIteration > 1 && (
						<audio ref={inProgressAudioRef}>
							<source src={inProgress} type='audio/mpeg' />
						</audio>
					)}
					<p
						className={styles['wheel-title-sec']}
						dangerouslySetInnerHTML={{ __html: t('product_details.spin_number', { num: wheelData?.product.streak_to_win }) }}
					/>
				</p>
				<div className={styles['wheel-wheel']}>
					<Wheel winnerSlot={winnerSlot} currentLabels={currentLabels} handleFinishWheelSpin={handleFinishWheelSpin} startWheel={startWheel} />
				</div>
			</section>
			{showModal && currentSpin.status === 'passed' && (
				<QualifiedModal
					onModalSubmit={onQualifiedModalSubmit}
					ticket_won={wheelData?.iterations[
						wheelData?.iteration_number === 0 ? wheelData?.iteration_number : wheelData?.iteration_number - 1
					]?.ticket_number.slots.filter((num) => num === currentSpin.winner_ticket)}
					iteration={wheelData?.iteration_number}
					streakToWin={wheelData?.product.streak_to_win}
				/>
			)}
			{showModal && currentSpin.status === 'passed' && (
				<audio autoPlay>
					<source src={qualified} type='audio/wav' />
				</audio>
			)}
			{showModal && (currentSpin.status === 'failed' || currentSpin.status === 'end') && (
				<UnQualifiedModal
					streak_to_win={wheelData?.product.streak_to_win}
					price={wheelData?.iterations[0]?.ticket_number.slots.length * wheelData?.product.price_per_product}
					isFinished={currentSpin.status === 'end'}
					onModalSubmit={onUnQualifiedModalSubmit}
					onModalDecline={onUnQualifiedModalDecline}
				/>
			)}
			{showModal && (currentSpin.status === 'failed' || currentSpin.status === 'end') && (
				<audio autoPlay>
					<source src={lose} type='audio/mpeg' />
				</audio>
			)}
			{showModal && currentSpin.status === 'win' && (
				<WinnerModal
					name={wheelData?.product.name}
					onModalSubmit={onWinnerModalSubmit}
					price={
						wheelData?.iterations[wheelData?.iteration_number === 0 ? wheelData?.iteration_number : wheelData?.iteration_number - 1]?.ticket_number
							.slots.length * wheelData?.product.price_per_product
					}
				/>
			)}
			{showModal && currentSpin.status === 'win' && (
				<audio autoPlay>
					<source src={win} type='audio/mpeg' />
				</audio>
			)}
		</div>
	);
}

export default LiveWheel;
