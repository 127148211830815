import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import { getWheels } from '../../services/tabServices';
import MyWheelsCard from '../MyWheelCard';
import myWheelsEmpty from '../../assets/images/my-wheels-empty.png';
import Loader from '../loader';

export default function MyWheelsTab() {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();

	const handleGetWheels = async (without) => {
		if (without) {
			const response = await getWheels();
			if (response.status === 200) {
				setData(response.data);
			} else {
				// handle error
			}
		} else {
			setLoading(true);
			const response = await getWheels();
			if (response.status === 200) {
				setData(response.data);
			} else {
				// handle error
			}
			setLoading(false);
		}
	};

	useEffect(() => {
		handleGetWheels();
	}, []);
	if (data && !loading) {
		return (
			<div className={styles['my-wheels']}>
				<div className={styles['my-wheels-container']}>
					{data?.length > 0 ? (
						data?.map((wheel, index) => (
							<div className={styles['my-wheels-product']} key={wheel.id}>
								<MyWheelsCard handleGetWheels={handleGetWheels} wheel={wheel} index={index} />
							</div>
						))
					) : (
						<img src={myWheelsEmpty} alt='' className={styles['my-wheels-empty']} />
					)}
				</div>
			</div>
		);
	} else {
		return <Loader />;
	}
}
