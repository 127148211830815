import styles from './index.module.scss';
import BigWinSlider from '../slider/bigWinSlider';
import { SwiperSlide } from 'swiper/react';
import avatar from '../../assets/icons/avatar.svg';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

export default function BigWin({ wonBig }) {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const getMonths = (month) => {
		switch (month) {
			case 0:
				return t('months.january');
			case 1:
				return t('months.february');
			case 2:
				return t('months.march');
			case 3:
				return t('months.april');
			case 4:
				return t('months.may');
			case 5:
				return t('months.june');
			case 6:
				return t('months.july');
			case 7:
				return t('months.august');
			case 8:
				return t('months.september');
			case 9:
				return t('months.october');
			case 10:
				return t('months.november');
			case 11:
				return t('months.december');
			default:
				return '';
		}
	};

	const handleBigWinNavigate = (card) => {
		navigate(`/product/${card.product.slug}`);
	};

	if (wonBig) {
		return (
			<div className={styles['big-win']}>
				<h3 className={styles['big-win-header']}>{t('big_win.just_won')}</h3>
				<BigWinSlider>
					{wonBig.map((card) => (
						<SwiperSlide key={card.id}>
							<div onClick={() => handleBigWinNavigate(card)} className={styles['card-border']}>
								<div className={styles['card']}>
									<div className={styles['card-image-container']}>
										<img className={styles['card-image']} src={card.product.thumbnail} />
										<p
											className={styles['product-details-content-wheel-text-sec']}
											dangerouslySetInnerHTML={{ __html: t('product_details.spin_number', { num: card.product.streak_to_win }) }}
										/>
									</div>
									<div className={styles['card-content']}>
										<p className={styles['card-content-name']}>{card.product.name}</p>
										<p className={styles['card-content-congrats']}>{t('big_win.congrats')}</p>
										<div className={styles['card-content-user']}>
											<img
												className={styles['user-image']}
												src={card.winner_data.profile_picture ? `${axios.defaults.baseURL}${card.winner_data.profile_picture}` : avatar}
											/>
											<p className={styles['user-name']}>{card.winner_data.full_name}</p>
										</div>
										<div className={styles['card-content-details']}>
											<p className={styles['details-ticket']}>
												{t('big_win.ticket_number')} <span>{card.ticket_number?.slots.join(',')}</span>
											</p>
										</div>
										<div className={styles['card-content-time']}>
											<p className={styles['time-date']}>{`${new Date(card.updated_at).getHours().toString().padStart(2, '0')}:${new Date(
												card.updated_at
											)
												.getMinutes()
												.toString()
												.padStart(2, '0')} ${new Date(card.updated_at).getDate()} ${getMonths(new Date(card.updated_at).getMonth())} ${new Date(
												card.updated_at
											).getFullYear()}`}</p>
										</div>
									</div>
								</div>
							</div>
						</SwiperSlide>
					))}
				</BigWinSlider>
			</div>
		);
	}
}
