import styles from './index.module.scss';
import emptyHeart from '../../assets/icons/empty-heart.svg';
import filledHeart from '../../assets/icons/filled-heart.svg';
import star from '../../assets/icons/star.svg';
import discountStar from '../../assets/icons/discount-star.png';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { addToFavourites, getFavouriteProducts, removeFromFavourites } from '../../services/sharedService';
import { setSnackbar } from '../../store/slices/snackbarSlice';
import { setFavourites } from '../../store/slices/favouritesSlice';
import Button from '../formButton';
import { useState } from 'react';
import { SwiperSlide } from 'swiper/react';
import AutoSlider from '../slider/autoSlider';
import Ticket from '../ticket';
import { useTranslation } from 'react-i18next';
import Slider from '../slider';
import { refundWheel } from '../../services/liveWheelService';

export function MyWheelsCard({ handleGetWheels, wheel }) {
	const [showModal, setShowModal] = useState(false);
	const favourites = useSelector((state) => state.favourites);
	const user = useSelector((state) => state.user);
	const { t, i18n } = useTranslation();
	const date = new Date(wheel.updated_at);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const getMonths = (month) => {
		switch (month) {
			case 0:
				return t('january');
			case 1:
				return t('february');
			case 2:
				return t('march');
			case 3:
				return t('april');
			case 4:
				return t('may');
			case 5:
				return t('june');
			case 6:
				return t('july');
			case 7:
				return t('august');
			case 8:
				return t('september');
			case 9:
				return t('october');
			case 10:
				return t('november');
			case 11:
				return t('december');
			default:
				return '';
		}
	};

	const getStatus = () => {
		switch (wheel.status) {
			case 'not_started':
				return t('my_profile.not_started');
			case 'in_progress':
				return t('my_profile.in_progress');
			case 'completed':
				return t('my_profile.completed');
			case 'failed':
				return t('my_profile.failed');
			case 'refundable':
				return t('my_profile.refundable');
			case 'refunded':
				return t('my_profile.refunded');
			default:
				return '';
		}
	};
	const handleWheelClick = () => {
		setShowModal(true);
	};
	const handleGetFavourites = async () => {
		const response = await getFavouriteProducts();
		if (response.status === 200) {
			dispatch(setFavourites(response.data));
		} else if (response.status === 401) {
			navigate('/login');
		}
	};
	const handleFavoriteClick = async (e) => {
		e.stopPropagation();
		if (user) {
			if (favourites.length > 0) {
				if (favourites.some((favourite) => favourite.slug === wheel.product.slug)) {
					const response = await removeFromFavourites(wheel.product.slug);
					if (response.status === 200) {
						handleGetFavourites();
					} else if (response.status === 401) {
						navigate('/login');
					} else {
						dispatch(
							setSnackbar({
								display: true,
								variant: 'error',
								message: response.response.data.message,
							})
						);
					}
				} else {
					const response = await addToFavourites(wheel.product.slug);
					if (response.status === 200) {
						handleGetFavourites();
					} else if (response.status === 401) {
						navigate('/login');
					} else {
						dispatch(
							setSnackbar({
								display: true,
								variant: 'error',
								message: response.response.data.message,
							})
						);
					}
				}
			} else {
				const response = await addToFavourites(wheel.product.slug);
				if (response.status === 200) {
					handleGetFavourites();
				} else if (response.status === 401) {
					navigate('/login');
				} else {
					dispatch(
						setSnackbar({
							display: true,
							variant: 'error',
							message: response.response.data.message,
						})
					);
				}
			}
		} else {
			dispatch(
				setSnackbar({
					display: true,
					variant: 'error',
					message: t('login_first'),
				})
			);
			navigate('/login');
		}
	};
	const handleCloseModal = () => {
		setShowModal(false);
	};
	const handleRefund = async () => {
		const response = await refundWheel(wheel.id);
		if (response.status === 200) {
			handleGetWheels(true);
			dispatch(
				setSnackbar({
					display: true,
					variant: 'success',
					message: response.data.message,
				})
			);
			handleCloseModal();
		} else {
			dispatch(
				setSnackbar({
					display: true,
					variant: 'error',
					message: response.response.data.message,
				})
			);
			handleCloseModal();
		}
	};
	const handleModalSubmit = () => {
		if (wheel.status === 'refundable') {
			handleRefund();
		} else if (wheel.status === 'not_started' || wheel.have_pending_iteration) {
			navigate(`/live-wheel/${wheel.id}`);
		} else {
			if (selectedTickets.length === 0) {
				dispatch(
					setSnackbar({
						display: true,
						variant: 'error',
						message: t('select_ticket'),
					})
				);
				return;
			}
			navigate(`/live-wheel/${wheel.id}?ticket=${selectedTickets[0]}`);
		}
	};

	const [selectedTickets, setSelectedTickets] = useState([]);
	const toogleSelectTicket = (num) => {
		if (selectedTickets.includes(num)) {
			setSelectedTickets(selectedTickets.filter((ticket) => ticket !== num));
		} else {
			setSelectedTickets([num]);
		}
	};
	const getWheelIteration = (iteration) => {
		if (iteration === wheel.product.streak_to_win) {
			return i18n.language === 'en-US' ? `Final` : 'آخر';
		}
		switch (iteration) {
			case 0:
				return i18n.language === 'en-US' ? `First` : 'أول';
			case 1:
				return i18n.language === 'en-US' ? `Second` : 'ثاني';
			case 2:
				return i18n.language === 'en-US' ? `Third` : 'ثالث';
			case 3:
				return i18n.language === 'en-US' ? `Fourth` : 'رابع';
			case 4:
				return i18n.language === 'en-US' ? `Fifth` : 'خامس';
			case 5:
				return i18n.language === 'en-US' ? `Sixth` : 'سادس';
			default:
				return i18n.language === 'en-US' ? `${iteration - 1}th` : `${iteration - 1}`;
		}
	};

	return (
		<>
			<div className={styles['product-border']}>
				<div className={`${styles['product']} ${wheel.status === 'completed' ? styles['product-won'] : ''}`}>
					<div className={styles['product-image-container']}>
						<img
							onClick={handleFavoriteClick}
							src={wheel.product.is_favourite || favourites.some((favourite) => favourite.slug === wheel.product.slug) ? filledHeart : emptyHeart}
							className={styles['product-favourite']}
							alt='favourite'
						/>
						<img src={wheel.product.thumbnail} className={styles['product-image']} alt={wheel.product.name} />
						<p
							className={styles['product-details-content-wheel-text-sec']}
							dangerouslySetInnerHTML={{ __html: t('product_details.spin_number', { num: wheel.product.streak_to_win }) }}
						/>
					</div>
					<div className={styles['product-content']}>
						{wheel.status === 'completed' ? (
							<div className={styles['product-winner-ticket']} aria-winner={`${wheel.iteration_number - 1}/${wheel.iteration_number}`}>
								<img src={star} alt='' />
							</div>
						) : (
							<div className={styles['product-winner-ticket']} aria-winner={`${wheel.iteration_number - 1}/${wheel.product.streak_to_win}`}>
								<img src={discountStar} alt='' />
							</div>
						)}
						<h3 className={styles['product-name']}>{wheel.product.name}</h3>
						<div className={styles['product-extra']}>
							<p className={styles['product-extra-date']}>
								{`${getMonths(date.getMonth())} ${date.getDate()}, ${date.getFullYear()}, ${
									date.getHours() > 12
										? (date.getHours() - 12).toLocaleString('en-US', { minimumIntegerDigits: 2 })
										: date.getHours().toLocaleString('en-US', { minimumIntegerDigits: 2 })
								}:${date.getMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2 })} ${date.getHours() >= 12 ? t('pm') : t('am')}`}
							</p>
						</div>
					</div>
					<div className={styles['product-footer']}>
						<div className={styles['product-state']}>{getStatus()}</div>
						<button onClick={handleWheelClick} className={styles['product-action']}>
							{t('my_profile.view_ticket')}
						</button>
					</div>
				</div>
			</div>
			{showModal && (
				<div className={styles['modal']}>
					<div className={styles['modal-container']}>
						<div className={styles['modal-content']}>
							<p className={styles['modal-header']}>{t('my_profile.ticket_numbers')}</p>
							<p className={styles['modal-text']}>{wheel.product.name}</p>
							{wheel.status !== 'completed' &&
							wheel.status !== 'failed' &&
							wheel.status !== 'refundable' &&
							wheel.status !== 'refunded' &&
							!wheel.have_pending_iteration ? (
								<Slider slidesPerView={3} slidesPerGroup={1} spaceBetween={28}>
									{Array.from({ length: 8 }, (_, i) => i + 1).map((num) => {
										return (
											<SwiperSlide key={num}>
												<Ticket selectedTickets={selectedTickets} number={num} toogleSelectTicket={toogleSelectTicket} />
											</SwiperSlide>
										);
									})}
								</Slider>
							) : (
								<AutoSlider number_of_tickets={wheel.iterations[0].ticket_number.slots.length}>
									{wheel.iterations[0].ticket_number.slots.map((num) => (
										<SwiperSlide key={num}>
											<Ticket
												number={num}
												selectedTickets={
													wheel.iterations[wheel.iteration_number === 0 ? wheel.iteration_number : wheel.iteration_number - 1].ticket_number.slots
												}
											/>
										</SwiperSlide>
									))}
								</AutoSlider>
							)}
							{wheel.status !== 'refundable' && wheel.status !== 'refunded' && (
								<>
									{wheel.status !== 'completed' && wheel.status !== 'failed' ? (
										<p className={styles['modal-hint']}>
											{wheel.iteration_number === 0
												? t('my_profile.you_bought_bought', { iteration: getWheelIteration(wheel.iteration_number) })
												: t('my_profile.you_bought_not', { iteration: getWheelIteration(wheel.iteration_number) })}
										</p>
									) : (
										<p className={styles['modal-hint']}>{t('my_profile.you_bought_error')}</p>
									)}
								</>
							)}
							{wheel.status === 'refundable' && (
								<p className={styles['modal-hint']}>
									{t('my_profile.refund_text', { amount: wheel.product.price_per_product * wheel.iterations[0].ticket_number.slots.length })}
								</p>
							)}
							{wheel.status === 'refunded' && (
								<p className={styles['modal-hint']}>
									{t('my_profile.refunded_text', { amount: wheel.product.price_per_product * wheel.iterations[0].ticket_number.slots.length })}
								</p>
							)}
							{wheel.status !== 'completed' && wheel.status !== 'failed' && wheel.status !== 'refundable' && wheel.status !== 'refunded' && (
								<Button
									variant={'gradient'}
									handleSubmit={handleModalSubmit}
									text={t('my_profile.spin_button', { iteration: getWheelIteration(wheel.iteration_number) })}
									type={'button'}
								/>
							)}
							{wheel.status === 'refundable' ? (
								<Button handleSubmit={handleModalSubmit} text={t('my_profile.done_button')} type={'button'} />
							) : (
								<Button
									variant={wheel.status !== 'completed' && wheel.status !== 'failed' ? 'outline' : ''}
									handleSubmit={handleCloseModal}
									text={t('my_profile.done_button')}
									type={'button'}
								/>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default MyWheelsCard;
